import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { URL_LINKS } from "../constant/Link"

const Header = ({ logo, callIco, header }) => {
  const getActivePath = selectedUrl => {
    if (typeof window !== "undefined") {
      if (selectedUrl === window.location.pathname) return true
      else if (
        selectedUrl.includes("/courses/") &&
        window.location.pathname.includes("/courses/")
      )
        return true
      else if (
        selectedUrl.includes("/blogs/") &&
        window.location.pathname.includes("/blogs/")
      )
        return true
      return false
    }
    return false
  }

  return (
    <header>
      <div className="topbar">
        <div className="launch-offer">
          {header.title1} <span>{header.title2}</span> {header.title3}
        </div>
        <div className="become-trainer">{header.sidderTitle}</div>
      </div>
      <nav className="navbar navbar-expand-xl navbar-light main-navbar">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Brand" className="img-fluid brand-image" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#main_navbar"
          aria-controls="main_navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="main_navbar">
          <ul className="navbar-nav ml-auto mr-0">
            {URL_LINKS.map(linkData => (
              <li
                className={
                  getActivePath(linkData.url) ? "nav-item active" : "nav-item"
                }
                key={linkData.name}
              >
                <Link className="nav-link" to={linkData.url}>
                  {linkData.name}
                  {getActivePath(linkData.url) && (
                    <span className="sr-only">(current)</span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
          {/* <div className="navbar-nav ml-md-auto">
            <span className="whatsapp">
              <img
                src={callIco}
                alt="call icon cal-sm-6"
                className="img-responsive call-icon"
              />
              <span className="cal-sm-6" style={{ margin: "auto" }}>
                {header.call} <strong>{header.number}</strong>
              </span>
            </span>
          </div> */}
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
