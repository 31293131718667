import React from "react"

import { IoMdCheckmark } from "react-icons/io"
import {
  FaFacebookF,
  FaGooglePlusG,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa"

const Footer = () => {
  return (
    <div>
      <div className="Footer">
        <a href="#" className="WhatsApp">
          <FaWhatsapp></FaWhatsapp>
        </a>
        <div className="container">
          <h2 className="sec-title">
            Discover the best of you.
            <br /> Subscribe now
          </h2>
          <h6 className="Subs_subTitle">
            Suscribe us now so you dont miss any future update from us
          </h6>
          <form className="subscribe">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email address"
              />
              <a href="/" className="btnCheck">
                <IoMdCheckmark className="checkICO" />
              </a>
            </div>
          </form>

          <div className="connectUs">
            <p>Connect with us</p>
            <div>
              <a href="/">
                <FaFacebookF className="SocialICO" />
              </a>
              <a href="/">
                <FaGooglePlusG className="SocialICO" />
              </a>
              <a href="/">
                <FaLinkedinIn className="SocialICO" />
              </a>
              <a href="/">
                <FaTwitter className="SocialICO" />
              </a>
              <a href="/">
                <FaYoutube className="SocialICO" />
              </a>
            </div>
          </div>
          <div className="footerBottom">
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <p>© 2020 All Right Reserved</p>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="MenuBottom">
                  <a href="/">About Us</a>
                  <a href="/">Become a Trainer</a>
                  <a href="/">Blogs</a>
                  <a href="/">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
