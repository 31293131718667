/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer"

const logoQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    headerData: site {
      siteMetadata {
        siteData {
          header {
            title1
            title2
            title3
            sidderTitle
            call
            number
          }
        }
      }
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(logoQuery)
  const {
    site,
    headerData: {
      siteMetadata: {
        siteData: { header },
      },
    },
  } = data
  const logo= 'https://res.cloudinary.com/dyhvdwmys/image/upload/q_auto:low,f_auto/v1582701713/Brainy-Fied/logo_f483ym.png'
  const callIco= 'https://res.cloudinary.com/dyhvdwmys/image/upload/q_auto:low,f_auto/v1582701804/Brainy-Fied/call_ico_wksfhl.png'
  return (
    <>
      <Header
        siteTitle={site.siteMetadata.title}
        logo={logo}
        callIco={callIco}
        header={header}
      />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
