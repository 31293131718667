import React from "react"
import { MdViewList, MdViewModule, MdViewStream } from "react-icons/md"
export const URL_LINKS = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Students",
    url: "/student/",
  },
  {
    name: "Freshers",
    url: "/freshers/",
  },
  {
    name: "Professionals",
    url: "/professionals/",
  },
  {
    name: "Courses",
    url: "/courses/",
  },
  {
    name: "Blogs",
    url: "/blogs/",
  },
  {
    name: "Life at Educorp",
    url: "/lifeatEducorp/",
  },
  {
    name: "About us",
    url: "/aboutUs/",
  },
  {
    name: "Trainers",
    url: "/trainers/",
  },
  {
    name: "Contact us",
    url: "/contactUs/",
  },
]

export const TAB_LINKS = [
  {
    name: "All",
    url: "/#pills-all",
    id: "pills-all",
  },
  {
    name: "Internship programs",
    url: "/#pills-internship",
    id: "pills-internship",
  },
  {
    name: "Career Oriented",
    url: "/#pills-career",
    id: "pills-career",
  },
  {
    name: "Professional Skills",
    url: "/#pills-skills",
    id: "pills-skills",
  },
]
export const BLOG_LINKS = [
  {
    name: <MdViewList />,
    url: "/blogs/#list",
    id: "list",
  },
  {
    name: <MdViewModule />,
    url: "/blogs/#module",
    id: "module",
  },
  {
    name: <MdViewStream />,
    url: "/blogs/#stream",
    id: "stream",
  },
]
